import axios from "axios";

const BeerService = {
    saveBeer: async(beer) => {
        await axios.post(process.env.REACT_APP_SERVER_ADDRESS + '/beer', beer)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    getBeers: async() => {
        const response =  await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/beers')
            .catch((error) => {
                throw error
            })

        return response.data.result
    },

    deleteBeer: async(id) => {
        await axios.delete(process.env.REACT_APP_SERVER_ADDRESS + '/beer', {data: {id: id}})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
                throw error
            })
    }
}

export default BeerService
