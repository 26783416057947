import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    TextField
} from "@material-ui/core";
import {useState} from "react";
import Typography from "@material-ui/core/Typography";
import ProcessService from "../../services/ProcessService";
import {toast} from "react-toastify";
import CarbonatorService from "../../services/CarbonatorService";
import {useForm} from "react-hook-form";
import FermenterService from "../../services/FermenterService";

const useStyles = makeStyles(() => {
    return {
        field: {
            marginBottom: "10px",
            display: "block",
            color: "primary"
        },
        form: {
            margin: "10px"
        }
    }
})

const CardButton = ({process, updateProcesses}) => {

    //Extra
    const classes = useStyles()
    const {register, handleSubmit, formState: {errors}, reset} = useForm();

    //Dialogs
    const [showChangeStageDialog, setShowChangeStageDialog] = useState(false);
    const [showSelectCarbonatorDialog, setShowSelectCarbonatorDialog] = useState(false);
    const [showSelectFermenterDialog, setShowSelectFermenterDialog] = useState(false);
    const [showEndConfirmationDialog, setShowEndConfirmationDialog] = useState(false);

    //Data
    const [freeCarbonators, setFreeCarbonators] = useState([])
    const [freeFermenters, setFreeFermenters] = useState([])
    const [target, setTarget] = useState(null)

    //Stage change
    const changeState = async (currentStage, targetStage, machineId) => {
        const data = {
            id: process.id,
            current_stage: currentStage,
            target_stage: targetStage,
            machine_id: machineId
        }

        const toastId = toast.info("Cambiando estado...", {hideProgressBar: true})

        try{
            await ProcessService.changeStage(data)
            toast.dismiss(toastId)
            if (targetStage === 'end') {
                toast.success("Lote finalizado")
            } else {
                toast.success("Estado cambiado")
            }
            await updateProcesses()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado.")
        }
    }

    const targetMaduration = async () => {
        if (process.stage === 'fermentation') {
            await changeState('fermentation', 'maduration', null)
            setShowChangeStageDialog(false)
        } else {
            //Currently carbonation. Must choose fermenter
            setTarget('maduration')
            await selectFermenterOnOpen()
        }
    }
    const targetFermentation = async () => {
        if (process.stage === 'maduration') {
            await changeState('maduration', 'fermentation', null)
            setShowChangeStageDialog(false)
        } else {
            //Currently carbonation. Must choose fermenter
            setTarget('fermentation')
            await selectFermenterOnOpen()
        }
    }
    const targetCarbonation = async () => {
        await selectCarbonatorOnOpen()
    }
    const endProcess = async () => {
        setShowEndConfirmationDialog(true)
    }

    const selectFermenter = async (data) => {
        await changeState('carbonation', target, data.machine_id)
        setTarget(null)
        selectFermenterOnClose()
    }
    const selectCarbonator = async (data) => {
        await changeState(process.stage , 'carbonation', data.machine_id)
        setTarget(null)
        selectCarbonatorOnClose()
    }
    const confirmEndProcess = async () => {
        await changeState(process.stage, 'end', null)
        setShowEndConfirmationDialog(false)
        setShowChangeStageDialog(false)
    }

    //SelectFermenterDialogOperations
    const selectFermenterOnClose = () => {
        setShowSelectFermenterDialog(false)
        setFreeFermenters([])
        reset()
    }
    const selectFermenterOnOpen = async () => {
        const toastId = toast.info("Cargando...", {hideProgressBar: true})
        try {
            await getFreeFermenters()
            setShowSelectFermenterDialog(true)
        } catch (e) {
            toast.error("Ha sucedido un error inesperado.")
        }
        toast.dismiss(toastId);
        setShowChangeStageDialog(false)
        setShowSelectFermenterDialog(true)
    }
    const getFreeFermenters = async () => {
        try {
            const array = await FermenterService.getFreeFermenters()
            setFreeFermenters(array)
        } catch (e) {
            throw e;
        }
    }

    //SelectCarbonatorDialogOperations
    const selectCarbonatorOnOpen = async () => {
        const toastId = toast.info("Cargando...", {hideProgressBar: true})
        try {
            await getFreeCarbonators()
            setShowSelectCarbonatorDialog(true)
        } catch (e) {
            toast.error("Ha sucedido un error inesperado.")
        }
        toast.dismiss(toastId);
        setShowChangeStageDialog(false)
        setShowSelectCarbonatorDialog(true)
    }
    const selectCarbonatorOnClose = () => {
        setFreeCarbonators([])
        setShowSelectCarbonatorDialog(false)
        reset()
    }
    const getFreeCarbonators = async () => {
        try {
            const array = await CarbonatorService.getFreeCarbonators()
            setFreeCarbonators(array)
        } catch (e) {
            throw e;
        }
    }

    return(
        <>
            <IconButton color="primary" onClick={() => setShowChangeStageDialog(true)}>
                <SettingsRoundedIcon fontSize="large"/>
            </IconButton>

            <Dialog
                open={showChangeStageDialog}
                onClose={() => setShowChangeStageDialog(false)}>

                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Cambiar etapa
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} direction="column" alignItems="center">
                        {process.stage !== 'fermentation' &&
                            (
                                <Grid item style={{minWidth: '100%'}}>
                                    <Button style={{minWidth: '100%'}} color="primary" variant="outlined" onClick={async () => targetFermentation()}>
                                        Fementación
                                    </Button>
                                </Grid>
                            )
                        }

                        {process.stage !== 'maduration' &&
                            (
                                <Grid item style={{minWidth: '100%'}}>
                                    <Button style={{minWidth: '100%'}} color="primary" variant="outlined" onClick={async () => targetMaduration()}>
                                        Maduración
                                    </Button>
                                </Grid>
                            )
                        }

                        {process.stage !== 'carbonation' &&
                            (
                                <Grid item style={{minWidth: '100%'}}>
                                    <Button style={{minWidth: '100%'}} color="primary" variant="outlined" onClick={async () => targetCarbonation()}>
                                        Carbonatación
                                    </Button>
                                </Grid>
                            )
                        }
                        <Grid item style={{minWidth: '100%'}}>
                            <Button style={{minWidth: '100%'}} color="primary" variant="contained" onClick={() => endProcess()}>
                                Finalización
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showSelectFermenterDialog}
                onClose={() => selectFermenterOnClose()}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Seleccionar fermentador
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit(selectFermenter)}>
                        <TextField
                            className={classes.field}
                            label="Fermentador"
                            variant="outlined"
                            fullWidth
                            required
                            select
                            defaultValue=''
                            {...register('machine_id', {required: 'Fermentador requerido'})}
                            error={Boolean(errors.machine_id)}
                            helperText={errors.machine_id?.message}>
                            {freeFermenters.map( (fermenter) =>
                                <MenuItem key={fermenter.id} value={fermenter.id}>{fermenter.name}</MenuItem>
                            )}
                        </TextField>

                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="outlined">
                                    Aceptar
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    onClick={() => selectFermenterOnClose()}
                                    type="reset"
                                    color="primary"
                                    variant="outlined">
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showSelectCarbonatorDialog}
                onClose={() => selectCarbonatorOnClose()}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Seleccionar carbonatador
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit(selectCarbonator)}>
                        <TextField
                            className={classes.field}
                            label="Carbonatador"
                            variant="outlined"
                            fullWidth
                            required
                            select
                            defaultValue=''
                            {...register('machine_id', {required: 'Carbonatador requerido'})}
                            error={Boolean(errors.machine_id)}
                            helperText={errors.machine_id?.message}>
                            {freeCarbonators.map( (carbonator) =>
                                <MenuItem key={carbonator.id} value={carbonator.id}>{carbonator.name}</MenuItem>
                            )}
                        </TextField>

                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="outlined">
                                    Aceptar
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    onClick={() => selectCarbonatorOnClose()}
                                    type="reset"
                                    color="primary"
                                    variant="outlined">
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showEndConfirmationDialog}
                onClose={() => setShowEndConfirmationDialog(false)}>

                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Activar alerta
                    </Typography>
                </DialogTitle>

                <DialogContent>

                    <p style={{margin: '0px 10px 20px 10px'}}>Esta seguro que desea finalizar el proceso?</p>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => confirmEndProcess()}
                                type="button"
                                color="primary"
                                variant="outlined">
                                Aceptar
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => setShowEndConfirmationDialog(false)}
                                type="button"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>

            </Dialog>

        </>
    )
}

export default CardButton