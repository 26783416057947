import {useState} from "react";
import {Button, Dialog, DialogTitle, Grid, List, ListItem, ListItemText, Menu, MenuItem} from "@material-ui/core";
import {toast} from "react-toastify";
import CSVService from "../../services/CSVService";
import ProcessService from "../../services/ProcessService";
import Typography from "@material-ui/core/Typography";

const CSVMenu = () => {

    const [anchorEl, setAnchorEl] = useState(null)
    const [processes, setProcesses] = useState([])
    const [showProcessesDialog, setShowProcessesDialog] = useState(false)

    const downloadAlarmsCSV = async () => {
        const toastId = toast.info("Descargando CSV...", {hideProgressBar: true})
        try{
            await CSVService.downloadAlarmsCSV()
            toast.dismiss(toastId)
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado.")
        }
    }
    const downloadCSV = async (process) => {
        const toastId = toast.info("Descargando CSV...", {hideProgressBar: true})
        try{
            await CSVService.downloadCSVForProcess(process)
            toast.dismiss(toastId)
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado.")
        }
    }

    const openProcessesDialog = async () => {
        setAnchorEl(null)
        const toastId = toast.info("Cargando...", {hideProgressBar: true})
        try {
            await getProcesses()
            setShowProcessesDialog(true);
        } catch (e) {
            toast.error("Ha sucedido un error inesperado.")
        }
        toast.dismiss(toastId);
    }
    const getProcesses = async () => {
        try {
            const array = await ProcessService.getLastProcesses()
            await setProcesses(array.result)
        } catch (e) {
            throw e;
        }
    }

    return(
        <>
            <Button style={{textTransform: "none", boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.16)', borderRadius: '10px'}}
                    color="secondary"
                    variant="contained"
                    onClick={(event) => setAnchorEl(event.currentTarget)}>
                Descargar CSV
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <MenuItem onClick={async () => downloadAlarmsCSV()}>Alarmas</MenuItem>
                <MenuItem onClick={async () => openProcessesDialog()}>Procesos</MenuItem>
            </Menu>

            <Dialog
                open={showProcessesDialog}
                onClose={() => setShowProcessesDialog(false)}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Descargar CSV de proceso
                    </Typography>
                </DialogTitle>

                <div style={{margin: '10px'}}>
                    <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                        {processes.map((process) =>
                            <ListItem key={process.id}>
                                <ListItemText
                                    primary={process.name}
                                    secondary={'Cerveza: ' + process.beer}/>
                                <Button variant="outlined" color="primary" style={{marginLeft: '20px'}}
                                        onClick={async () => downloadCSV(process)}>
                                    Descargar
                                </Button>
                            </ListItem>
                        )}
                    </List>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => setShowProcessesDialog(false)}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid></div>
            </Dialog>
        </>
)
}

export default CSVMenu