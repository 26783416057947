import {Grid, makeStyles} from "@material-ui/core"
import {useMemo, useState} from "react";
import ProcessService from "../services/ProcessService";
import {toast} from "react-toastify";
import Crontab from "reactjs-crontab";
import ProcessCard from "./ProcessCard";
import {HashLoader} from "react-spinners";
import {css} from "@emotion/react";

const useStyles = makeStyles({
    container: {
        margin: '30px',
        height: '100%'
    },

    card: {
        padding: '30px',
        textAlign: 'center'
    },

    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '80vh',
    }

})

const Panel = () => {

    const classes = useStyles();

    const [processes, setProcesses] = useState(null)

    const getProcesses = async () => {
        try {
            const response = await ProcessService.getProcesses();
            setProcesses(response)
        } catch (e) {
            console.log(e)
            toast.error("Ha sucedido un error inesperado. No se ha podido obtener informacion de los procesos.")
        }

    }

    //spimner
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: #b84b3b;`

    //Scheduler
    const schedule = useMemo(() => [
            {
                fn: () => getProcesses(),
                config: '* * * * *',
                id: '1', // optional
                name: 'Get processes' // optional
            }
        ], [])

    return (
        <div className={classes.container}>
            <Crontab
                tasks={schedule}
                timeZone='UTC' // UTC timezone.
                dashboard={{
                    hidden: true, // if true, dashboard is hidden
                }}
            />
            {processes != null ?
                (
                    <Grid container spacing={4} alignItems="stretch">
                        {processes.map(process =>
                            <Grid item xs={12} sm={6} md={4} key={process.id}>
                                <ProcessCard process={process} updateProcesses={() => getProcesses()}/>
                            </Grid>
                        )}
                    </Grid>
                ) :
                (
                    <div className={classes.flexContainer}>
                        <HashLoader css={override} loading={true} color='#b84b3b'/>
                    </div>
                )
            }
        </div>
    )
}

export default Panel