import {
    Button,
    Dialog,
    DialogTitle,
    Grid,
    makeStyles,
    MenuItem,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useState} from "react";
import BeerService from "../../services/BeerService";
import FermenterService from "../../services/FermenterService";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import ProcessService from "../../services/ProcessService";

const useStyles = makeStyles(() => {
    return {
        field: {
            marginBottom: "10px",
            display: "block",
            color: "primary"
        },
        form: {
            margin: "10px"
        }
    }
})

const ProcessMenu = () => {
    const classes = useStyles();

    const [showCreateProcessDialog, setShowCreateProcessDialog] = useState(false);
    const [beers, setBeers] = useState([]);
    const [fermenters, setFermenters] = useState([]);

    const {register, handleSubmit, formState: {errors}, reset} = useForm();

    //Dialog operations
    const createProcessDialogOnOpen = async () => {
        const toastId = toast.info("Cargando...", {hideProgressBar: true})
        try {
            setBeers(await BeerService.getBeers());
            setFermenters(await FermenterService.getFreeFermenters());
            setShowCreateProcessDialog(true);
        } catch (e) {
            console.log(e)
            toast.error("Ha sucedido un error inesperado")
        }
        toast.dismiss(toastId)
    }
    const createProcessDialogOnClose = () => {
        setShowCreateProcessDialog(false);
        reset()
    }

    //Process operations
    const createProcess = async (data) => {
        const toastId = toast.info("Creando...", {hideProgressBar: true})
        try {
            await ProcessService.createProcess(data)
            toast.dismiss(toastId)
            toast.success("Proceso creado")
            createProcessDialogOnClose()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado")
        }
    }

    return(
        <>
            <Button variant="contained" color="secondary" style={{textTransform: "none", boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.16)', borderRadius: '10px'}} onClick={async () => createProcessDialogOnOpen()}>
                Crear lote
            </Button>

            {/*DIALOGS*/}
            {/*Create process dialog*/}
            <Dialog
                open={showCreateProcessDialog}
                onClose={() => createProcessDialogOnClose()}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Crear nuevo lote
                    </Typography>
                </DialogTitle>

                <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit(createProcess)}>

                    <TextField
                        className={classes.field}
                        label="Cerveza"
                        variant="outlined"
                        fullWidth
                        required
                        select
                        defaultValue=''
                        {...register('beer_id', {required: 'Cerveza requerida'})}
                        error={Boolean(errors.beer_id)}
                        helperText={errors.beer_id?.message}>
                            {beers.map( (beer) =>
                                <MenuItem key={beer.id} value={beer.id}>{beer.name}</MenuItem>
                            )}
                    </TextField>

                    <TextField
                        className={classes.field}
                        label="Fermentador"
                        variant="outlined"
                        fullWidth
                        required
                        select
                        defaultValue=''
                        {...register('fermenter_id', {required: 'Fermentador requerido'})}
                        error={Boolean(errors.fermenter_id)}
                        helperText={errors.fermenter_id?.message}>
                            {fermenters.map( (fermenter) =>
                                <MenuItem key={fermenter.id} value={fermenter.id}>{fermenter.name}</MenuItem>
                            )}
                    </TextField>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined">
                                Crear
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => createProcessDialogOnClose()}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        </>
    )
}

export default ProcessMenu