import {Grid, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const Login = () => {

    return(
        <div style={{backgroundColor: "#923223"}}>


            <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '100vh', backgroundColor: "pr"}}>

                <Grid item sm={12} xs={10}>
                    <Paper elevation={5} style={{padding: "30px"}}>
                        <Typography variant="h2">
                            MasTracking
                        </Typography>
                    </Paper>
                </Grid>

            </Grid>
        </div>
    )
}

export default Login
