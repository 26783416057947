import axios from "axios";

const CSVService = {

    downloadCSVForProcess: async (processInfo) => {
        await axios.post(process.env.REACT_APP_SERVER_ADDRESS + '/csv/process/temperatures', {id: processInfo.id}, {responseType: "blob"}
        ).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', processInfo.name + '_' + processInfo.beer.replace(/ /g, '_') +'.csv');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            throw error;
        });
    },

    downloadAlarmsCSV: async () => {
        await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/csv/alerts',{responseType: "blob"})
            .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const currentDate = new Date();
            const dateString = `${currentDate.getFullYear()}-` +
                                `${(currentDate.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}-` +
                                `${currentDate.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}`
            link.href = url;
            link.setAttribute('download', 'alarma_' + dateString +'.csv');
            document.body.appendChild(link);
            link.click();
        });
    }
}

export default CSVService