import {
    Button,
    Dialog,
    DialogTitle,
    Grid,
    List,
    ListItem, ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    TextField
} from "@material-ui/core";
import {useState} from "react";
import {useForm} from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import EmailService from "../../services/EmailService";
import {toast} from "react-toastify";

const useStyles = makeStyles(() => {
    return {
        field: {
            marginBottom: "10px",
            display: "block",
            color: "primary"
        },
        form: {
            margin: "10px"
        }
    }

})

const EmailMenu = () => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const {register, handleSubmit, formState: {errors}, reset} = useForm();

    const [showAddEmailDialog, setShowAddEmailDialog] = useState(false)
    const [showDeleteEmailDialog, setShowDeleteEmailDialog] = useState(false);
    const [emails, setEmails] = useState([])

    const addEmailDialogOnClose = () => {
        setShowAddEmailDialog(false)
        reset()
    }
    const addEmail = async (data) => {
        const toastId = toast.info("Agregando...", {hideProgressBar: true})
        try {
            await EmailService.addEmail(data)
            toast.dismiss(toastId)
            toast.success("Email agregado")
            addEmailDialogOnClose()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado")
        }
    }

    const deleteBeerDialogOnOpen = async() => {
        setAnchorEl(null)
        const toastId = toast.info("Cargando...", {hideProgressBar: true})
        try {
            await getEmails()
            setShowDeleteEmailDialog(true);
        } catch (e) {
            toast.error("Ha sucedido un error inesperado.")
        }
        toast.dismiss(toastId);
    }
    const getEmails = async() => {
        try {
            const array = await EmailService.getEmails()
            setEmails(array)
        } catch (e) {
            throw e;
        }
    }
    const deleteEmail = async (email) => {
        const toastId = toast.info("Guardando...", {hideProgressBar: true})

        try {
            await EmailService.deleteEmail(email)
            toast.dismiss(toastId)
            toast.success("Email borrado")
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado")
        }

        try {
            await getEmails()
        } catch (e) {
            console.log(e)
            toast.error("Ha sucedido un error inesperado.")
        }
    }

    return(
        <>
            <Button style={{textTransform: "none", boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.16)', borderRadius: '10px'}}
                    color="secondary"
                    variant="contained"
                    onClick={(event) => setAnchorEl(event.currentTarget)}>
                Emails
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <MenuItem onClick={() => setShowAddEmailDialog(true)}>Agregar email</MenuItem>
                <MenuItem onClick={() => deleteBeerDialogOnOpen()}>Eliminar email</MenuItem>
            </Menu>

            <Dialog
                open={showAddEmailDialog}
                onClose={() => addEmailDialogOnClose()}>

                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Agregar email
                    </Typography>
                </DialogTitle>

                <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit(addEmail)}>
                    <TextField
                        className={classes.field}
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        {...register('mail_address', {
                            required: {value: true, message: 'Email requerido'},
                            pattern: {value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: "Ingrese un email correcto"}})}
                        error={Boolean(errors.mail_address)}
                        helperText={errors.mail_address?.message}/>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined">
                                Agregar
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => addEmailDialogOnClose()}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>

            <Dialog
                open={showDeleteEmailDialog}
                onClose={() => setShowDeleteEmailDialog(false)}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Borrar emails
                    </Typography>
                </DialogTitle>

                <div style={{margin: '10px'}}>
                    <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                        {emails.map((email) =>
                            <ListItem key={email.mail_address}>
                                <ListItemText
                                    primary={email.mail_address}/>
                                <Button variant="outlined" color="primary" style={{marginLeft: '20px'}}
                                        onClick={async () => deleteEmail(email)}>
                                    Borrar
                                </Button>
                            </ListItem>
                        )}
                    </List>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => setShowDeleteEmailDialog(false)}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid></div>
            </Dialog>
        </>
    )
}

export default EmailMenu