import axios from "axios";

const ProcessService = {

    createProcess: async (processData) => {
        await axios.post(process.env.REACT_APP_SERVER_ADDRESS + '/process', processData)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    getProcesses: async () => {
        return await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/process/active')
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    deactivateAlert: async (data) => {
        return await axios.put(process.env.REACT_APP_SERVER_ADDRESS + '/alert/deactivate', data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },
    
    activateAlert: async (data) => {
        return await axios.put(process.env.REACT_APP_SERVER_ADDRESS + '/alert/activate', data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    modifyTemperature: async (data) => {
        return await axios.put(process.env.REACT_APP_SERVER_ADDRESS + '/temperature', data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    changeStage: async (data) => {
        await axios.put(process.env.REACT_APP_SERVER_ADDRESS + '/process/stage', data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    getGraphData: async (data) => {

        let rawData = [];

        await axios.post(process.env.REACT_APP_SERVER_ADDRESS + '/process/temperature', data)
            .then((response) => {
                rawData = response.data.result
            })
            .catch((error) => {
                throw error
            })

        return rawData;
    },

    getLastProcesses: async () => {
        return await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/process/last')
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    }
}

export default ProcessService;

