import {
    Button,
    Dialog,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles, Menu, MenuItem,
    TextField
} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {useState} from "react";
import BeerService from "../../services/BeerService";
import Typography from "@material-ui/core/Typography";
import {toast} from "react-toastify";

const useStyles = makeStyles(() => {
    return {
        field: {
            marginBottom: "10px",
            display: "block",
            color: "primary"
        },
        form: {
            margin: "10px"
        }
    }

})

const BeerMenu = () => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null)
    const [showCreateBeerDialog, setShowCreateBeerDialog] = useState(false);
    const [showDeleteBeerDialog, setShowDeleteBeerDialog] = useState(false);
    const [beers, setBeers] = useState([])

    //FormHook
    const {register, handleSubmit, formState: {errors}, reset} = useForm();

    //Dialog operations
    const createBeerDialogOnOpen = () => {
        setAnchorEl(null)
        setShowCreateBeerDialog(true)
    }
    const createBeerDialogOnClose = () => {
        setShowCreateBeerDialog(false)
        reset()
    }
    const deleteBeerDialogOnOpen = async() => {
        setAnchorEl(null)
        const toastId = toast.info("Cargando...", {hideProgressBar: true})
        try {
            await getBeers()
            setShowDeleteBeerDialog(true);
        } catch (e) {
            toast.error("Ha sucedido un error inesperado.")
        }
        toast.dismiss(toastId);
    }

    //Beer operations
    const createBeer = async(data) => {
        const toastId = toast.info("Guardando...", {hideProgressBar: true})
        try {
            await BeerService.saveBeer(data)
            toast.dismiss(toastId)
            toast.success("Cerveza creada")
            createBeerDialogOnClose()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado")
        }
    }

    const getBeers = async() => {
        try {
            const array = await BeerService.getBeers()
            setBeers(array)
        } catch (e) {
            throw e;
        }
    }

    const deleteBeer = async(beer) => {
        const toastId = toast.info("Eliminando...", {hideProgressBar: true})

        try {
            await BeerService.deleteBeer(beer.id)
            toast.dismiss(toastId)
            toast.success("Cerveza borrada")
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado")
        }

        try {
            await getBeers()
        } catch (e) {
            console.log(e)
            toast.error("Ha sucedido un error inesperado.")
        }
    }

    return(
        <>
            <Button style={{textTransform: "none", boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.16)', borderRadius: '10px'}}
                    color="secondary"
                    variant="contained"
                    onClick={(event) => setAnchorEl(event.currentTarget)}>
                Cervezas
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                <MenuItem onClick={() => createBeerDialogOnOpen()}>Crear cerveza</MenuItem>
                <MenuItem onClick={() => deleteBeerDialogOnOpen()}>Borrar cervezas</MenuItem>
            </Menu>

            {/*DIALOGS*/}
            {/*Create beer dialog*/}
            <Dialog
                open={showCreateBeerDialog}
                onClose={() => createBeerDialogOnClose()}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Crear nuevo tipo de cerveza
                    </Typography>
                </DialogTitle>

                <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit(createBeer)}>
                    <TextField
                        className={classes.field}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        {...register('name', {required: 'Nombre requerido'})}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}/>
                    <TextField
                        className={classes.field}
                        label="Temperatura de fermentación"
                        variant="outlined"
                        fullWidth
                        required
                        type="number"
                        {...register('fermentation_temp', {required: 'Temperatura de fermentacion requerida'})}
                        error={Boolean(errors.fermentation_temp)}
                        helperText={errors.fermentation_temp?.message}/>
                    <TextField
                        className={classes.field}
                        label="Temperatura de maduración"
                        variant="outlined"
                        fullWidth
                        required
                        type="number"
                        {...register('maduration_temp', {required: 'Temperatura de maduracion requerida'})}
                        error={Boolean(errors.fermentation_temp)}
                        helperText={errors.fermentation_temp?.message}/>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined">
                                Crear
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => createBeerDialogOnClose()}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>

            {/*Delete beer dialog*/}
            <Dialog
                open={showDeleteBeerDialog}
                onClose={() => setShowDeleteBeerDialog(false)}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Borrar cervezas
                    </Typography>
                </DialogTitle>

                <div style={{margin: '10px'}}>
                    <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                        {beers.map((beer) =>
                            <ListItem key={beer.id}>
                                <ListItemText
                                    primary={beer.name}
                                    secondary={
                                        'Fermentación: ' + beer.fermentation_temp + '°C' +
                                        ' | ' +
                                        'Maduración: ' + beer.maduration_temp + '°C'}/>
                                <Button variant="outlined" color="primary" style={{marginLeft: '20px'}}
                                        onClick={async () => deleteBeer(beer)}>
                                    Borrar
                                </Button>
                            </ListItem>
                        )}
                    </List>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => setShowDeleteBeerDialog(false)}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid></div>
            </Dialog>
        </>
    )
}

export default BeerMenu