import Panel from "./Panel";
import Typography from '@material-ui/core/Typography';
import {AppBar, createTheme, Grid, makeStyles, ThemeProvider, Toolbar} from "@material-ui/core";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BeerMenu from "./menus/BeerMenu";
import FermenterMenu from "./menus/FermenterMenu";
import CarbonatorMenu from "./menus/CarbonatorMenu";
import ProcessMenu from "./menus/ProcessMenu";
import CSVMenu from "./menus/CSVMenu";
import EmailMenu from "./menus/EmailMenu";

const theme = createTheme({
    palette: {
        primary: {
            main: '#99332f'
        },
        secondary: {
            main: '#b84b3b',
            contrastText: 'white'
        }
    },
    typography:{
        fontFamily: `"Montserrat", "Roboto"`,
        fontWeightRegular: 500,
    }
})

const useStyles = makeStyles((theme) => {
    return {
        appbarSpace: theme.mixins.toolbar
    }

})

const Main = ({roles}) => {

    //CSS
    const classes = useStyles();
    const isMobile = window.screen.width < 600

    //Functions
    const isAdmin = () => {
        return roles.find((role) => role === 'admin')
    }

    return (
        <ThemeProvider theme={theme}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div style={{backgroundColor: '#ffd396', minHeight: '100vh'}}>
                <AppBar>
                    <Toolbar>
                        <div style={{display: "flex", justifyContent:"space-between", width: "100%", flexWrap: "nowrap"}}>
                            <div style={{flexGrow: 1}}>
                                <Grid container spacing={2}>

                                    <Grid container direction="column" style={{maxWidth: '150px'}} justifyContent="center" alignContent="center">
                                        <Grid item>
                                            <Typography variant="h6">
                                                MasTracking
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {(!isMobile && isAdmin()) && (
                                        <Grid item>
                                            <BeerMenu/>
                                        </Grid>
                                    )}

                                    {(!isMobile && isAdmin()) && (
                                        <Grid item>
                                            <FermenterMenu/>
                                        </Grid>
                                    )}

                                    {(!isMobile && isAdmin()) && (
                                        <Grid item>
                                            <CarbonatorMenu/>
                                        </Grid>
                                    )}

                                    {!isMobile && (
                                        <Grid item>
                                            <ProcessMenu/>
                                        </Grid>
                                    )}

                                </Grid>
                            </div>

                            {(!isMobile) && (
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <CSVMenu/>
                                        </Grid>
                                        {isAdmin() && (
                                            <Grid item>
                                                <EmailMenu/>
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </div>
                    </Toolbar>
                </AppBar>

                <div className={classes.appbarSpace}/>

                <Panel/>
            </div>
        </ThemeProvider>
    );
}

export default Main;
