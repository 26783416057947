import {
    Button,
    Dialog,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles, Menu, MenuItem,
    TextField
} from "@material-ui/core";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import CarbonatorSerivce from "../../services/CarbonatorService";
import Typography from "@material-ui/core/Typography";
import PhysicalIdError from "../../errors/PhysicalIdError";

const useStyles = makeStyles(() => {
    return {
        field: {
            marginBottom: "10px",
            display: "block",
            color: "primary"
        },
        form: {
            margin: "10px"
        }
    }

})

const CarbonatorMenu = () => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [showCreateCarbonatorDialog, setShowCreateCarbonatorDialog] = useState(false);
    const [showDeleteCarbonatorDialog, setShowDeleteCarbonatorDialog] = useState(false);
    const [carbonators, setCarbonators] = useState([])

    //FormHook
    const {register, handleSubmit, formState: {errors}, reset} = useForm();

    //Dialog operations
    const createCarbonatorDialogOnOpen = () => {
        setAnchorEl(null)
        setShowCreateCarbonatorDialog(true)
    }
    const createCarbonatorDialogOnClose = () => {
        setShowCreateCarbonatorDialog(false)
        reset()
    }
    const deleteCarbonatorDialogOnOpen = async() => {
        setAnchorEl(null)
        const toastId = toast.info("Cargando...", {hideProgressBar: true})

        try {
            const array = await CarbonatorSerivce.getCarbonators()
            setCarbonators(array)
            setShowDeleteCarbonatorDialog(true);
        } catch (e) {
            console.log(e)
            toast.error("Ha sucedido un error inesperado.")
        }

        toast.dismiss(toastId)
    }

    //Carbonator operations
    const createCarbonator = async (data) => {
        const toastId = toast.info("Guardando...", {hideProgressBar: true})
        try {
            await CarbonatorSerivce.saveCarbonator(data)
            toast.dismiss(toastId)
            toast.success("Carbonatador creado")
            createCarbonatorDialogOnClose()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)

            if (e instanceof PhysicalIdError){
                toast.error("Ya existe un carbonatador con el id " + data.physical_id)
            } else {
                toast.error("Ha sucedido un error inesperado")
            }
        }
    }

    const getCarbonators = async() => {
        try {
            const array = await CarbonatorSerivce.getCarbonators()
            setCarbonators(array)
        } catch (e) {
            throw e;
        }
    }

    const deleteCarbonator = async(carbonator) => {
        const toastId = toast.info("Guardando...", {hideProgressBar: true})

        try {
            await CarbonatorSerivce.deleteCarbonator(carbonator.id)
            toast.dismiss(toastId)
            toast.success("Carbonatador borrado")
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado")
        }

        try {
            getCarbonators()
        } catch (e) {
            console.log(e)
            toast.error("Ha sucedido un error inesperado.")
        }
    }

    return(
        <>
            <Button style={{textTransform: "none", boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.16)', borderRadius: '10px'}}
                    color="secondary"
                    variant="contained"
                    onClick={(event) => setAnchorEl(event.currentTarget)}>
                Carbonatadores
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <MenuItem onClick={() => createCarbonatorDialogOnOpen()}>Crear carbonatador</MenuItem>
                <MenuItem onClick={() => deleteCarbonatorDialogOnOpen()}>Borrar carbonatadores</MenuItem>
            </Menu>

            {/*DIALOGS*/}
            {/*Create fermenter dialog*/}
            <Dialog
                open={showCreateCarbonatorDialog}
                onClose={() => createCarbonatorDialogOnClose()}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Crear nuevo carbonatador
                    </Typography>
                </DialogTitle>

                <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit(createCarbonator)}>
                    <TextField
                        className={classes.field}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        {...register('name', {required: 'Nombre requerido'})}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}/>

                    <TextField
                        className={classes.field}
                        label="Id (controlador)"
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        {...register('physical_id', {required: 'Id requerido'})}
                        error={Boolean(errors.physical_id)}
                        helperText={errors.physical_id?.message}/>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined">
                                Crear
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => createCarbonatorDialogOnClose()}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>

            {/*Delete fermenter dialog*/}
            <Dialog
                open={showDeleteCarbonatorDialog}
                onClose={() => setShowDeleteCarbonatorDialog(false)}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Borrar fermentadores
                    </Typography>
                </DialogTitle>

                <div style={{margin: '10px'}}>
                    <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                        {carbonators.map((carbonator) =>
                            <ListItem key={carbonator.id}>
                                <ListItemText
                                    primary={carbonator.name}/>
                                <Button variant="outlined" color="primary" style={{marginLeft: '20px'}}
                                        onClick={async () => deleteCarbonator(carbonator)}>
                                    Borrar
                                </Button>
                            </ListItem>
                        )}
                    </List>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => setShowDeleteCarbonatorDialog(false)}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid></div>
            </Dialog>
        </>
    )
}

export default CarbonatorMenu