import axios from "axios";

const EmailService = {

    addEmail: async (data) => {
        await axios.post(process.env.REACT_APP_SERVER_ADDRESS + '/mail', data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    getEmails: async () => {
        const response =  await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/mails')
            .catch((error) => {
                throw error
            })
        return response.data.result
    },

    deleteEmail: async (email) => {
        await axios.delete(process.env.REACT_APP_SERVER_ADDRESS + '/mail', {data: email})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
                throw error
            })
    }
}

export default EmailService