import {
    Button,
    Dialog,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles, Menu, MenuItem,
    TextField
} from "@material-ui/core";
import {useState} from "react";
import {useForm} from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import FermenterService from "../../services/FermenterService";
import {toast} from "react-toastify";
import PhysicalIdError from "../../errors/PhysicalIdError";

const useStyles = makeStyles(() => {
    return {
        field: {
            marginBottom: "10px",
            display: "block",
            color: "primary"
        },
        form: {
            margin: "10px"
        }
    }

})

const FermenterMenu = () => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null)
    const [showCreateFermenterDialog, setShowCreateFermenterDialog] = useState(false);
    const [showDeleteFermenterDialog, setShowDeleteFermenterDialog] = useState(false);
    const [fermenters, setFermenters] = useState([])

    //FormHook
    const {register, handleSubmit, formState: {errors}, reset} = useForm();

    //Dialog operations
    const createFermenterDialogOnOpen = () => {
        setAnchorEl(null)
        setShowCreateFermenterDialog(true)
    }
    const createFermenterDialogOnClose = () => {
        setShowCreateFermenterDialog(false)
        reset()
    }
    const deleteFermenterDialogOnOpen = async() => {
        setAnchorEl(null)
        const toastId = toast.info("Cargando...", {hideProgressBar: true})

        try {
            const array = await FermenterService.getFermenters()
            setFermenters(array)
            setShowDeleteFermenterDialog(true)
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado.")
        }

        toast.dismiss(toastId)
    }

    //Fermenter operations
    const createFermenter = async(data) => {
        const toastId = toast.info("Guardando...", {hideProgressBar: true})
        try {
            await FermenterService.saveFermenter(data)
            toast.dismiss(toastId)
            toast.success("Fermentador creado")
            createFermenterDialogOnClose()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)

            if (e instanceof PhysicalIdError){
                toast.error("Ya existe un fermentador con el id " + data.physical_id)
            } else {
                toast.error("Ha sucedido un error inesperado")
            }
        }
    }
    const getFermenters = async() => {
        try {
            const array = await FermenterService.getFermenters()
            setFermenters(array)
        } catch (e) {
            throw e;
        }
    }
    const deleteFermenter = async(fermenter) => {
        const toastId = toast.info("Guardando...", {hideProgressBar: true})

        try{
            await FermenterService.deleteFermenter(fermenter.id)
            toast.dismiss(toastId)
            toast.success("Fermentador borrado")
        } catch (e) {
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado")
        }

        try {
            getFermenters()
        } catch (e) {
            console.log(e)
            toast.error("Ha sucedido un error inesperado.")
        }
    }

    return (
        <>
            <Button style={{textTransform: "none", boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.16)', borderRadius: '10px'}}
                    color="secondary"
                    variant="contained"
                    onClick={(event) => setAnchorEl(event.currentTarget)}>
                Fermentadores
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <MenuItem onClick={() => createFermenterDialogOnOpen()}>Crear fermentador</MenuItem>
                <MenuItem onClick={() => deleteFermenterDialogOnOpen()}>Borrar fermentadores</MenuItem>
            </Menu>

            {/*DIALOGS*/}
            {/*Create fermenter dialog*/}
            <Dialog
                open={showCreateFermenterDialog}
                onClose={() => createFermenterDialogOnClose()}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Crear nuevo fermentador
                    </Typography>
                </DialogTitle>

                <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmit(createFermenter)}>
                    <TextField
                        className={classes.field}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        {...register('name', {required: 'Nombre requerido'})}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}/>

                    <TextField
                        className={classes.field}
                        label="Id (controlador)"
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        {...register('physical_id', {required: 'Id requerido'})}
                        error={Boolean(errors.physical_id)}
                        helperText={errors.physical_id?.message}/>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined">
                                Crear
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => createFermenterDialogOnClose()}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>

            {/*Delete fermenter dialog*/}
            <Dialog
                open={showDeleteFermenterDialog}
                onClose={() => setShowDeleteFermenterDialog(false)}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Borrar fermentadores
                    </Typography>
                </DialogTitle>

                <div style={{margin: '10px'}}>
                    <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                        {fermenters.map((fermenter) =>
                            <ListItem key={fermenter.id}>
                                <ListItemText
                                    primary={fermenter.name}/>
                                <Button variant="outlined" color="primary" style={{marginLeft: '20px'}}
                                        onClick={async () => deleteFermenter(fermenter)}>
                                    Borrar
                                </Button>
                            </ListItem>
                        )}
                    </List>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => setShowDeleteFermenterDialog(false)}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid></div>
            </Dialog>
        </>
    )

}

export default FermenterMenu