import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NotificationsOffRoundedIcon from "@mui/icons-material/NotificationsOffRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded';
import {useState} from "react";
import {toast} from "react-toastify";
import ProcessService from "../services/ProcessService";
import {useForm} from "react-hook-form";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import CSVService from "../services/CSVService";
import StageButton from "./cardButtons/StageButton";
import GraphButton from "./cardButtons/GraphButton";

const useStyles = makeStyles({
    card: {
        padding: '30px',
        textAlign: 'center'
    },
    field: {
        marginBottom: "10px",
        display: "block",
        color: "primary"
    },
    form: {
        margin: "10px"
    }
})

const ProcessCard = ({process, updateProcesses}) => {

    const classes = useStyles();
    const isMobile = window.screen.width < 600
    const [showDeactivateAlertDialog, setShowDeactivateAlertDialog] = useState(false)
    const [showActivateAlertDialog, setShowActivateAlertDialog] = useState(false)
    const [showModifyTemperatureDialog, setShowModifyTemperatureDialog] = useState(false)

    //FormHook
    const {register: registerAlert, handleSubmit: handleSubmitAlert, formState: {errors: errorsAlert}, reset: resetAlert} = useForm();
    const {register: registerTemperature, handleSubmit: handleSubmitTemperature, formState: {errors: errorsTemperature}, reset: resetTemperature} = useForm();

    //StageOperations

    //Alarm operations
    const deactivateAlarm = async (data) => {
        const toastId = toast.info("Desactivando alerta...", {hideProgressBar: true})
        try{
            await ProcessService.deactivateAlert({...data, process_id: process.id})
            toast.dismiss(toastId)
            toast.success("Alerta desactivada")
            deactivateAlertDialogOnClose()
            await updateProcesses()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado.")
        }
    }
    const activateAlarm = async () => {
        const toastId = toast.info("Activando alerta...", {hideProgressBar: true})
        try{
            await ProcessService.activateAlert({process_id: process.id})
            toast.dismiss(toastId)
            toast.success("Alerta activada")
            setShowActivateAlertDialog(false)
            await updateProcesses()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado.")
        }
    }
    const deactivateAlertDialogOnClose = () => {
        setShowDeactivateAlertDialog(false)
        resetAlert()
    }

    //TemperatureOperations
    const modifyTemperature = async (data) => {
        const toastId = toast.info("Modificando temperatura objetivo...", {hideProgressBar: true})
        try{
            await ProcessService.modifyTemperature({...data, process_id: process.id})
            toast.dismiss(toastId)
            toast.success("Temperatura objetivo modificada")
            modifyTemperatureDialogOnClose()
            await updateProcesses()
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado.")
        }
    }
    const modifyTemperatureDialogOnClose = () => {
        setShowModifyTemperatureDialog(false)
        resetTemperature()
    }

    //CSVOperations
    const downloadCSV = async () => {
        const toastId = toast.info("Descargando CSV...", {hideProgressBar: true})
        try{
            await CSVService.downloadCSVForProcess(process)
            toast.dismiss(toastId)
        } catch (e) {
            console.log(e)
            toast.dismiss(toastId)
            toast.error("Ha sucedido un error inesperado.")
        }
    }

    //Auxiliary functions
    const stage = (stage) => {
        switch (stage) {
            case 'fermentation':
                return 'Fermentación'
            case 'maduration':
                return 'Maduración'
            case 'carbonation':
                return 'Carbonatación'
            default:
                return 'ERROR'
        }
    }
    const temperatureTarget = () => {
        if(process.target_temperature != null){
            return process.target_temperature
        } else {
            switch (process.stage) {
                case 'fermentation':
                    return process.fermentation_temp
                case 'maduration':
                    return process.maduration_temp
                case 'carbonation':
                    return 3
                default:
                    return 'ERROR'
            }
        }
    }
    const warning = () => {
        if (process.alarm_activated) {
            if (process.current_temperature != null) {
                if (Math.abs(process.current_temperature - temperatureTarget(process)) > 3) {
                    return '#ff9d72'
                }
            }
            return "white"
        } else {
            return '#cacaca'
        }
    }
    const options ={dateStyle: 'medium', timeStyle: 'short', timeZone: 'Etc/GMT'}// { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric'}
    const timestampToString = (timestampInicio) => {
        let date = Date.parse(timestampInicio)
        date = new Date(date)
        return date.toLocaleString("es-ES", options)
    }
    const fechaEstimadaDeFinalizacion = (timestampInicio) => {
        let date = Date.parse(timestampInicio)
        date = date + 1000*60*60*24*7; //Se agregan 7 dias
        date = new Date(date)
        return date.toLocaleString("es-ES", options)
    }

    return (
        <>
            <Card className={classes.card}
                  style={{minHeight: '100%', padding: 0, backgroundColor: warning(), borderRadius: '25px', 'boxShadow': '21px 19px 35px -23px rgba(0,0,0,0.25)'}}>
                <CardContent style={{minHeight: '100%'}}>
                    <div style={{
                        minHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        {/*Data de proceso*/}
                        <div style={{margin: 20}}>
                            {/*Titulo*/}
                            <Typography variant={"h4"} gutterBottom>
                                Lote {process.name}
                            </Typography>

                            {/*Temperaturas*/}
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="h5">
                                        {process.current_temperature == null ?
                                            (<i>NaN</i>) :
                                            process.current_temperature + '°C'}
                                    </Typography>
                                    <div style={{fontSize: '18px'}}>
                                        Actual
                                    </div>
                                </Grid>
                                <Divider orientation="vertical" flexItem>
                                </Divider>
                                <Grid item xs>
                                    <Typography variant="h5">
                                        {temperatureTarget() + '°C'}
                                    </Typography>
                                    <div style={{fontSize: '18px'}}>
                                        Objetivo
                                    </div>
                                </Grid>
                            </Grid>

                            {/*Informacion*/}
                            <Table size='small' style={{padding: 0, marginTop: '10px'}}>
                                <TableBody>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row"
                                                   style={{paddingRight: 0}}>Inicio</TableCell>
                                        <TableCell align="right"
                                                   style={{fontVariantNumeric: 'tabular-nums'}}>{timestampToString(process.fecha_inicio)}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row"
                                                   style={{paddingRight: 0}}>Finalizacion Est.</TableCell>
                                        <TableCell align="right"
                                                   style={{fontVariantNumeric: 'tabular-nums'}}>{fechaEstimadaDeFinalizacion(process.fecha_inicio)}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row"
                                                   style={{paddingRight: 0}}>Etapa</TableCell>
                                        <TableCell align="right">{stage(process.stage)}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row"
                                                   style={{paddingRight: 0}}>Cambio etapa</TableCell>
                                        <TableCell align="right">{process.fecha_cambio_etapa ?
                                            timestampToString(process.fecha_cambio_etapa):
                                            '-'}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row"
                                                   style={{paddingRight: 0}}>Cerveza</TableCell>
                                        <TableCell align="right">{process.beer}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row"
                                                   style={{paddingRight: 0}}>Fermentador</TableCell>
                                        <TableCell align="right">{process.fermenter}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row"
                                                   style={{paddingRight: 0}}>Carbonatador</TableCell>
                                        <TableCell align="right">
                                            {process.carbonator == null ? (<i>-</i>) : process.carbonator}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>

                        {/*Botones*/}
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item>
                                {process.alarm_activated ?
                                    (
                                        <IconButton color="primary" onClick={() => setShowDeactivateAlertDialog(true)}>
                                            <NotificationsOffRoundedIcon fontSize="large"/>
                                        </IconButton>
                                    ) :
                                    (
                                        <IconButton color="primary" onClick={() => setShowActivateAlertDialog(true)}>
                                            <NotificationsRoundedIcon fontSize="large"/>
                                        </IconButton>
                                    )
                                }
                            </Grid>

                            <Grid item>
                                <IconButton color="primary" onClick={() => setShowModifyTemperatureDialog(true)}>
                                    <DeviceThermostatRoundedIcon fontSize="large"/>
                                </IconButton>
                            </Grid>

                            <Grid item>
                                <StageButton process={process} updateProcesses={updateProcesses}/>
                            </Grid>

                            <Grid item>
                                <IconButton color="primary">
                                    <DownloadForOfflineRoundedIcon fontSize="large" onClick={() => downloadCSV(process.id)}/>
                                </IconButton>
                            </Grid>

                            {!isMobile && (
                                <Grid item>
                                    <GraphButton process={process}/>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </CardContent>
            </Card>

            <Dialog
                open={showDeactivateAlertDialog}
                onClose={() => deactivateAlertDialogOnClose()}
            >
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Desactivar alerta
                    </Typography>
                </DialogTitle>

                <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmitAlert(deactivateAlarm)}>
                    <TextField
                        className={classes.field}
                        label="Horas"
                        variant="outlined"
                        fullWidth
                        required
                        type="number"
                        {...registerAlert('alarm_hours_deactivated', {required: true, min: 1})}
                        error={Boolean(errorsAlert.alarm_hours_deactivated)}
                        helperText={errorsAlert.alarm_hours_deactivated && (
                            errorsAlert.alarm_hours_deactivated.type === 'required' ?
                                'Cantidad de horas requeridas' :
                                'No se puede setear un numero menor a cero'
                        )}/>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined">
                                Desactivar
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => deactivateAlertDialogOnClose()}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>

            <Dialog
                open={showActivateAlertDialog}
                onClose={() => setShowActivateAlertDialog(false)}
            >
                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Activar alerta
                    </Typography>
                </DialogTitle>

                <p style={{margin: '0px 10px 10px'}}>Esta seguro que desea reactivar la alerta?</p>

                <div style={{margin: '10px'}}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => activateAlarm()}
                                type="button"
                                color="primary"
                                variant="outlined">
                                Activar
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => setShowActivateAlertDialog(false)}
                                type="button"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>

            <Dialog
                open={showModifyTemperatureDialog}
                onClose={() => modifyTemperatureDialogOnClose()}>

                <DialogTitle disableTypography={true}>
                    <Typography variant="h6" component='h2' style={{textAlign: 'center'}}>
                        Modificar temperatura
                    </Typography>
                </DialogTitle>

                <form noValidate autoComplete="off" className={classes.form} onSubmit={handleSubmitTemperature(modifyTemperature)}>
                    <TextField
                        className={classes.field}
                        label="Temperatura"
                        variant="outlined"
                        fullWidth
                        required
                        type="number"
                        {...registerTemperature('target_temperature', {required: true, min: 1, max: 50})}
                        error={Boolean(errorsTemperature.target_temperature)}
                        helperText={errorsTemperature.target_temperature && (
                            errorsTemperature.target_temperature.type === 'required' ?
                                'Cantidad de horas requeridas' :
                                (errorsTemperature.target_temperature.type === 'min' ?
                                    'No se puede setear una temperatura menor a 0°C' :
                                    'No se puede setear una temperatura mayor a 50°C')
                        )}/>

                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined">
                                Modificar
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => modifyTemperatureDialogOnClose()}
                                type="reset"
                                color="primary"
                                variant="outlined">
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </form>

            </Dialog>

        </>
    )
}

export default ProcessCard