import axios from "axios";
import PhysicalIdError from "../errors/PhysicalIdError";

const CarbonatorService = {
    saveCarbonator : async(carbonator) => {
        await axios.post(process.env.REACT_APP_SERVER_ADDRESS + '/carbonator', carbonator)
            .then((response) => {
                const result = response.data.result
                const status = response.data.status
                if(status === 409){
                    throw new PhysicalIdError(result);
                }
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    getCarbonators: async () => {
        const response =  await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/carbonators')
            .catch((error) => {
                throw error
            })

        return response.data.result
    },

    deleteCarbonator: async(id) => {
        await axios.delete(process.env.REACT_APP_SERVER_ADDRESS + '/carbonator', {data: {id: id}})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    getFreeCarbonators: async () => {
        const response =  await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/free_carbonators')
            .catch((error) => {
                throw error
            })

        return response.data.result
    }

}

export default CarbonatorService