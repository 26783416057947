import TimelineIcon from '@mui/icons-material/Timeline';
import {Dialog, DialogContent, IconButton, Paper} from "@material-ui/core";
import {useState} from "react";
import {toast} from "react-toastify";
import ProcessService from "../../services/ProcessService";
import {ResponsiveLine} from '@nivo/line'

const GraphButton = ({process}) => {

    const [showGraphDialog, setShowGraphDialog] = useState(false)
    const [processedData, setProcessedData] = useState([])
    const [tickValues, setTickValues] = useState(0)

    const graphDialogOnOpen = async () => {
        const toastId = toast.info("Cargando...", {hideProgressBar: true})
        try {
            await getData()
            setShowGraphDialog(true);
        } catch (e) {
            toast.error("Ha sucedido un error inesperado.")
        }
        toast.dismiss(toastId);


    }

    const dateStringGenerator = (date) => {
        return `${date.getFullYear()}-` +
            `${(date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}-` +
            `${date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})} ` +
            `${date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}:` +
            `${date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}:` +
            `${date.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}`;
    }
    const getData = async () => {
        try {
            const array = await ProcessService.getGraphData({process_id: process.id})
            tickValuesFunction(array)
            await processData(array)
        } catch (e) {
            throw e;
        }
    }
    const processData = async (data) =>{
        let targetTemperatureData = []
        let actualTemperatuteData = []
        data.map(row => {
            const date = new Date(row.timestamp + "-6:00")
            const date1 = new Date("2021/01/01")
            console.log(date1)
            console.log(date1.getMonth())
            console.log(date1.getDate())
            const dateString = dateStringGenerator(date)
            targetTemperatureData.push({
                x: dateString,
                y: row.temperature
            })

            actualTemperatuteData.push({
                x: dateString,
                y: row.target_temperature
            })
        })

        const finalData = [
            {
                id: 'Objetivo',
                color: "hsl(335, 70%, 50%)",
                data: targetTemperatureData
            },
            {
                id: 'Actual',
                color: "hsl(54, 70%, 50%)",
                data: actualTemperatuteData
            }
        ]

        await setProcessedData(finalData)
    }
    const graphTooltip = (data) => {
        const date = data.point.data.x
        const temperature = data.point.data.y
        const dateString = dateStringGenerator(date);
        return (
            <>
                <Paper style={{padding: "10px"}}>
                    <strong>Fecha:</strong> {dateString}
                    <br/>
                    <strong>Temperatura:</strong> {temperature}°C
                </Paper>
            </>
        )
    }
    const tickValuesFunction = (array) => {
        const initialDate = new Date(array[0].timestamp)
        const finalDate = new Date(array[array.length - 1].timestamp)
        const diffHours = Math.ceil((finalDate - initialDate)/(1000 * 60 * 60));

        if(diffHours < 20) {
            setTickValues(1)
        } else {
            setTickValues(Math.ceil(diffHours/20))
        }
    }

    return (
        <>
            <IconButton color="primary" disabled={process.current_temperature == null}>
                <TimelineIcon fontSize="large" onClick={async () => graphDialogOnOpen()}/>
            </IconButton>

            <Dialog
                open={showGraphDialog}
                onClose={() => setShowGraphDialog(false)}
                maxWidth="xl">

                <DialogContent style={{height: '500px', minWidth: '1000px'}}>
                    <ResponsiveLine
                        data={processedData}
                        margin={{ top: 50, right: 110, bottom: 120, left: 80 }}
                        xScale={{type: "time", format: "%Y-%m-%d %H:%M:%S", precision: "minute" }}
                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        tooltip={graphTooltip}
                        axisBottom={{
                            orient: 'bottom',
                            format: "%Y-%m-%d %H:%M:%S",
                            tickPadding: 5,
                            tickRotation: -45,
                            tickValues: `every ${tickValues} hours`,
                            legend: 'Fecha',
                            legendOffset: 110,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Temperatura',
                            legendOffset: -60,
                            legendPosition: 'middle'
                        }}
                        enablePoints={true}
                        pointSize={2}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        curve="monotoneX"
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default GraphButton