import Login from "./components/Login";
import {useAuth0} from "@auth0/auth0-react";
import Main from "./components/Main";
import './App.css';
import {useEffect} from "react";

const App = () => {

    const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0()

    useEffect(() => {
        if (!isLoading) {
            if (!isAuthenticated) {
                loginWithRedirect()
            }
        }
    }, [isLoading, isAuthenticated, loginWithRedirect])

    return (
        <>
            {isAuthenticated ? (<Main roles={user['https://mastracking.mastra.com.uy/roles']}/>) : (<Login/>)}
        </>
    )
}

export default App;
