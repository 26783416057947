import axios from "axios";
import PhysicalIdError from "../errors/PhysicalIdError";

const FermenterService = {
    saveFermenter: async(fermenter) => {
        await axios.post(process.env.REACT_APP_SERVER_ADDRESS + '/fermenter', fermenter)
            .then((response) => {
                const result = response.data.result
                const status = response.data.status
                if(status === 409){
                    throw new PhysicalIdError(result);
                }
                return result
            })
            .catch((error) => {
                throw error
            })
    },

    getFermenters: async() => {
        const response = await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/fermenters')
            .catch((error) => {
                throw error
            })

        return response.data.result
    },

    deleteFermenter: async(id) => {
        await axios.delete(process.env.REACT_APP_SERVER_ADDRESS + '/fermenter', {data: {id: id}})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error
            })
    },

    getFreeFermenters: async() => {
        const response = await axios.get(process.env.REACT_APP_SERVER_ADDRESS + '/free_fermenters')
            .catch((error) => {
                throw error
            })

        return response.data.result
    },
}

export default FermenterService